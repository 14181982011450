import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"


const Cooptech = () => {

	const data = useStaticQuery(graphql`
	query {
		top: file(relativePath: { eq: "cooptech/top.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 350) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}`
)

	return(
		<Layout>
			<SEO title="Cooptech" />
            <div className="card mb-3 mb-md-4">
				<div className="card-body">
					<div className="cooptech__cert">
						<div className="row justify-content-between align-items-center">
							<div className="col-lg-7 col-xl-5">
								<h1>Cooptech</h1>
								<div className="d-none d-lg-block">
									<p className='mb-3'>What is wrong with the way tech companies operate today? In a darwinian market the answer is nothing! It is survival of the fittest. The ones that survive and deliver the vast wealth to investors are celebrated and the failures are sacrificed. CoopTech is a new approach that provides a little more certainty for investors, employees and customers of tech businesses. In this way the proportion of sacrifices is lower and we can celebrate more.</p>
									<p>There are 5 items on the CoopTech Charter that should be followed before any investment be made:</p>
								</div>
							</div>
							<div className="col-lg-5 col-xl-6">
								<div className="coop__cert__img mb-2 mb-lg-0">
									<Img fluid={data.top.childImageSharp.fluid} />
								</div>
								<div className="d-block d-lg-none">
									<p className='mb-3'>What is wrong with the way tech companies operate today? In a darwinian market the answer is nothing! It is survival of the fittest. The ones that survive and deliver the vast wealth to investors are celebrated and the failures are sacrificed. CoopTech is a new approach that provides a little more certainty for investors, employees and customers of tech businesses. In this way the proportion of sacrifices is lower and we can celebrate more.</p>
									<p>There are 5 items on the CoopTech Charter that should be followed before any investment be made:</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ul className='cooptech__info-list mb-2'>
				<li>
					<div className="cooptech__info-list__item">
						<span>1</span>
						An exit partner is a stakeholder who commits to an outcome within a reasonable number of years (usually 3)
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>2</span>
						The value of a user’s activity over the lifetime of the CoopTech can be quantified
					</div>
				</li>

				<li>
					<div className="cooptech__info-list__item">
						<span>3</span>
						Customers (or users) are granted the status of stakeholder by offering a ownership model based on their contribution to the success of the business
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>4</span>
						A limit is placed on investment raised to cover the required capital over the anticipated life of the CoopTech so that investors can measure their return in advance
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>5</span>
						Employees are incentivised based on these preceding four items
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>6</span>
						Users who reach the Coop membership status will be entitled to receive a share of the profits for that year of membership
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>7</span>
						All Coop members will receive annual financial statements
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>8</span>
						Users will be able to vote on representatives who will attend Coop Steering Committee meetings at least once a year
					</div>
				</li>
				<li>
					<div className="cooptech__info-list__item">
						<span>9</span>
						All users who are Coop members at time of a sale of the company will also be entitled to a share of the Coop Membership allocation
					</div>
				</li>
			</ul>

			<div className="card mb-3">
				<div className="card-body text-center">
					<p className='mb-2'>The results of this approach will be lower investment risk and more certainty in outcomes. Management of CoopTech requires significant planning before anything is invested or code is written or users are on-boarded.</p>
					<p className='mb-0'>But the results are spectacular.</p>
				</div>
			</div>


		</Layout>
	)
}

export default Cooptech
